// extracted by mini-css-extract-plugin
export const sectionFormPrescription = "formPrescription-module--sectionFormPrescription--2emHm";
export const flexStyles = "formPrescription-module--flexStyles--z5zGP";
export const filesInput = "formPrescription-module--filesInput--PfZDb";
export const formInput = "formPrescription-module--form-input--2hAH3";
export const labelRadio = "formPrescription-module--label-radio--3hlbi";
export const fake = "formPrescription-module--fake--1ZSiA";
export const radio = "formPrescription-module--radio--xEv_c";
export const date = "formPrescription-module--date--3t9k1";
export const calendar = "formPrescription-module--calendar--2bb51";
export const medicationTextarea = "formPrescription-module--medicationTextarea--2Loo-";
export const buttonClose = "formPrescription-module--button-close--vsu_x";
export const buttonOpen = "formPrescription-module--button-open--2jMnZ";
export const submit = "formPrescription-module--submit--3N-sh";
export const textareaContainer = "formPrescription-module--textarea-container--1vMzx";
export const refill = "formPrescription-module--refill--1gKRA";